import React, { Component } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { SiteTitle } from "../components/elements/SiteTitle";

import { Footer } from "../components/collections/Footer";
import { LogoPanel } from "../panels/logoPanel";

class Index extends Component {
  render() {
    return (
      <Layout>
        <SiteTitle>Privacy Policy</SiteTitle>

        <LogoPanel />
        <Footer />
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  # query MyQuery {
  #   allWordpressPage(filter: {slug: {nin: ["home", "blog", "privacy-policy", "contact", "portfolio"]}}) {
  #     edges {
  #       node {
  #         slug
  #         title
  #         type
  #         status
  #         wordpress_id
  #         link
  #         menu_order
  #       }
  #     }
  #   }
  # }

  query privacyPageQuery {
    wordpressPage(slug: { eq: "privacy-policy" }) {
      slug
      title
      content
    }
  }
`;
